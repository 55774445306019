import React, {useContext, useState} from 'react';
import {useSelector} from 'react-redux';
import {Route, Switch} from "react-router-dom";
import {Layout} from 'antd';

import SiderMenu from './SiderMenu';
import {QuestionContentProvider} from "../context/questionContext";
import QuestionList from "../pages/admin/question/question.list";
import CatalogForm from "../pages/admin/catalog/catalog.form";
import EditorForm from "../pages/super/editor/editor.form";
import EditorList from "../pages/super/editor/editor.list";
import EditorView from "../pages/super/editor/editor.view";
import CatalogContent from "../pages/super/catalog/catalog.content";
import AllCatalogsPage from "../pages/super/catalog/catalog.super.list";
import PlayerList from "../pages/super/player/player.list";
import PlayerDetailView from "../pages/super/player/player.detail.view";
import ProfileAdminView from "../pages/admin/profile/profile.admin.view";
import ProfileSuperView from "../pages/super/profile/profile.super.view";
import CatalogAllView from "../pages/admin/catalog/catalog.all.view";
import GeneralCatalogView from "../components/catalog/catalog.general.view";
import TanView from "../pages/tan/list/tan.view";
import QuestionStatsView from "../pages/statistic/questions/question.stats.view";
import ExtendedSearchView from "../pages/admin/search/ExtendedSearchView";
import MyQuestionsSearchView from "../pages/admin/search/MyQuestionsSearchView";
import GeneralQuestionsSearchView from "../pages/admin/search/GeneralQuestionsSearchView";
import ContentGroupView from "../pages/content-groups/ContentGroupView";
import ContentGroupDetailView from "../pages/content-groups/ContentGroupDetailView";
import QuestionFormPage from "../pages/question-form/QuestionFormPage";
import TanImportPage from "../pages/tan/import/TanImportPage";
import ScrollToTop from "../hooks/ScrollToTop";


const {Content, Header} = Layout;

const MainLayout = (props) => {

    const {updateHandler} = props;
    const authRole = useSelector(state => state.auth?.user?.user?.role);
    const [collapsed, setCollapsed] = useState(false);


    const handleOnCollapse = () => {
        setCollapsed(prevState => !prevState);
    };

    return (
        <Layout style={{minHeight: '100vh'}}>
            <SiderMenu collapsed={collapsed} handleOnCollapse={handleOnCollapse} parentHandler={updateHandler}/>
            <Layout style={{background: '#fff'}}>

                <Content style={{margin: '24px 16px 0'}}>
                    <div style={{padding: 24, background: '#fff', minHeight: 800}}>
                        {(authRole === 'admin') && (
                            <QuestionContentProvider>
                                <ScrollToTop>
                                    <Switch>
                                        <Route exact path="/question-editor" component={QuestionFormPage}/>

                                        <Route exact path="/question/:id" component={QuestionFormPage}/>
                                        <Route exact path="/question-list" component={MyQuestionsSearchView}/>
                                        <Route exact path="/catalog-list" component={CatalogAllView}/>
                                        <Route exact path="/general-catalog" component={GeneralQuestionsSearchView}/>
                                        <Route exact path="/search" component={ExtendedSearchView}/>
                                        <Route exact path="/content-groups" component={ContentGroupView}/>
                                        <Route exact path="/content-group-detail/:id"
                                               component={ContentGroupDetailView}/>
                                        <Route exact path="/catalog-edit" component={CatalogForm}/>
                                        <Route exact path="/catalog-content" component={CatalogContent}/>
                                        <Route exact path="/profile" component={ProfileAdminView}/>
                                        <Route exact path="/player-detail" component={PlayerDetailView}/>

                                        <Route exact path="/question-stats" component={QuestionStatsView}/>
                                        <Route exact path="/tan" component={TanView}/>
                                        <Route exact path="/tan/import" component={TanImportPage}/>

                                        <Route path='' component={QuestionFormPage}/>

                                    </Switch>
                                </ScrollToTop>

                            </QuestionContentProvider>
                        )}

                        {(authRole === 'super') && (

                            <QuestionContentProvider>
                                <ScrollToTop>
                                    <Switch>
                                        <Route exact path="/question-list" component={QuestionList}/>
                                        <Route exact path="/editor-create" component={EditorForm}/>
                                        <Route exact path="/editor/:id" component={EditorForm}/>
                                        <Route exact path="/editor-list" component={EditorList}/>
                                        <Route exact path="/editor-view" component={EditorView}/>
                                        <Route exact path="/catalog-content" component={CatalogContent}/>
                                        <Route exact path="/catalog-list" component={AllCatalogsPage}/>
                                        <Route exact path="/general-catalog" component={GeneralCatalogView}/>
                                        <Route exact path="/player-list" component={PlayerList}/>
                                        <Route exact path="/player-detail" component={PlayerDetailView}/>
                                        <Route exact path="/profile" component={ProfileSuperView}/>
                                        <Route exact path="/question-stats" component={QuestionStatsView}/>
                                        <Route exact path="/tan" component={TanView}/>
                                        <Route exact path="/tan/import" component={TanImportPage}/>

                                        <Route path='' component={EditorList}/>
                                    </Switch>
                                </ScrollToTop>
                            </QuestionContentProvider>
                        )}

                    </div>
                </Content>
            </Layout>
        </Layout>
    );
}

export default MainLayout;
